import './App.css';
import React from 'react';
import HeaderMenu from './componets/header/Header';
import TrackingPage from './page/tracking/Tracking';
import FooterPage from './componets/footer/FooterPage';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <div className='App'>
      <HeaderMenu />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            {/* <Route path={'/'} element={<TrackingPage />} /> */}
            <Route path={'/:company'} element={<TrackingPage />} />
            <Route path={'/:company/:id'} element={<TrackingPage />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
      <FooterPage />
    </div>
  );
};

export default App;
