import logger from 'redux-logger';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import saga from '../saga/RootSaga';
import trackingReducer from './slice/TrackingSlice';
import componentReducer from './slice/ComponentSlice';

const persistConfig = { key: 'rapidwork_tracking', storage };
const rootReducer = combineReducers({
  Tracking: trackingReducer,
  Component: componentReducer,
});
const persistorReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: persistorReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      thunk: false,
    }).concat(sagaMiddleware, logger),
});
//logger เอาไว้ดู data redux
sagaMiddleware.run(saga);

const persistor = persistStore(store);

export { persistor, store };
