import axios from 'axios';
import join from 'url-join';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 2000,
});

axiosClient.interceptors.request.use(
  async (config) => {
    var url = `${process.env.REACT_APP_BASE_URL}`;
    var port = ``;

    // port = `${process.env.REACT_APP_LOOP_TRACKING_PORT}`;

    // if (config.url.indexOf('/v5/order/manager/company/') !== -1) {
    //   port = `${process.env.REACT_APP_LOOP_TRACKING_PORT}`;
    // }


    let setUrl = url;
    if (port !== undefined) {
      setUrl += `:${port}`;
    }
    config.url = join(`${url}${port}`, config.url);
    console.log('url', url);
    console.log('port', port);
    console.log('config', config.url);

    return config;
  },
  (error) => {
    return error.config;
  }
);

const get = (path, params) => {
  return axiosClient.get(path, params);
};

const post = (path, data) => {
  console.log('path post', path);
  console.log('data post', data);
  return axiosClient.post(path, data);
};

const put = (path, data) => {
  return axiosClient.put(path, data);
};

const deleted = (path, data) => {
  return axiosClient.delete(path, data);
};
const HttpUtilFormData = { get, post, put, deleted };
export default HttpUtilFormData;
