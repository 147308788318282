import React, { useState } from 'react';
import { FrownOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { Row, Col, Card, Divider, Steps, Grid } from 'antd';
import css from './TrackingDetail.module.css';
import PropTypes from 'prop-types';
import IconButton from '../../../componets/button/Iconbutton';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const { Step } = Steps;
const { useBreakpoint } = Grid;

const TrackingDetail = ({ search }) => {
  const { data } = useSelector(({ Tracking }) => Tracking.dataListOrderTracking);
  const [isitem, setIsItem] = useState(true);
  const screens = useBreakpoint();

  console.log('dataSearch', data);

  const customDot = (dot, { status, index, title }) => {
    if (status === 'wait') {
      <div className={css.stepTitle}>{title}</div>;
    } else if (status === 'procress') {
    }
  };

  // const Datas = {
  //   orderNo: 'xxxxxxx',
  //   senderName: 'xxxxxxx xxxxxxxx',
  //   senderAddress: 'ccccccccccc',
  //   recipientName: 'xxxxxxx xxxxxxxx',
  //   recipientAddress: 'ccccccccccc',
  //   IconTrackingCurrentStatus: 'https://manager.rapidtech.app/file/xxxxxx',
  //   currentStatusIndex: 3,
  //   statusTracking: [
  //     {
  //       timestampTxt: 'Tue, Apr 11 02:38 AM',
  //       timestampUnix: 1681180704,
  //       orderStatus: 'confirm',
  //       statusName: 'Sorting',
  //       statusDescription: 'xxxxxxxxxxxxxxxxxxxxxxxx',
  //     },
  //     {
  //       timestampTxt: 'Tue, Apr 11 02:30 AM',
  //       timestampUnix: 1681180204,
  //       orderStatus: 'open',
  //       trackingStatus: 'toShip',
  //       statusName: 'To Ship',
  //       statusDescription: 'xxxxxxxxxxxxxxxxxxxxxxxx',
  //     },
  //   ],
  // };

  return (
    <div>
      {search === false || data.orderNo === '-' ? (
        <>
          <div>
            <FrownOutlined style={{ fontSize: '31px', color: '#909aaf' }} />
          </div>
          <div className={css.labelText}>
            <text>No Parcel</text>
            <div className={css.subText}>Your Parcel will appear here.</div>
          </div>
        </>
      ) : (
        data.orderNo !== '-' && (
          <div>
            <Card>
              {/* -------------------------------- */}
              {screens.xl === false ? (
                <Row>
                  <Col xs={{ span: 2 }} md={2} xl={2} sm={2}>
                    <div className={screens.xs === true ? css.boxIcon : css.boxCheckIcon}>
                      {
                        <img
                          src={data.IconTrackingCurrentStatus}
                          className={screens.xl === false && screens.xs === true ? css.iconAllMoblie : css.iconAll}
                          alt='Your SVG'
                        />
                      }
                    </div>
                  </Col>

                  <Col xs={{ span: 21, offset: 0 }} md={{ span: 22 }} xl={{ span: 22, offset: 0 }} sm={{ span: 22 }}>
                    <Row gutter={screens.xs === true ? [16, 18] : [16, 4]} align='bottom'>
                      <Col xs={{ span: 18, offset: 2 }} md={{ span: 14, offset: 2 }} xl={{ span: 12, offset: 0 }} sm={{ span: 8, offset: 0 }}>
                        <div className={screens.xs === true ? css.trackTextMobile : css.trackText}>{data.orderNo}</div>
                      </Col>

                      <Col
                        xs={{ span: 1, offset: 0 }}
                        md={{ span: 7, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                        sm={{ span: 8, offset: 0 }}
                        className={css.arrowIcon}
                      >
                        {console.log('breakpoint', screens)}

                        <IconButton
                          onClick={() => {
                            setIsItem(!isitem);
                          }}
                        >
                          {isitem === true ? <UpOutlined /> : <DownOutlined />}
                        </IconButton>
                      </Col>

                      {screens.xs === true ? (
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          md={{ span: 0, offset: 0 }}
                          sm={{ span: 0, offset: 0 }}
                          xl={{ span: 0, offset: 0 }}
                          className={css.trackText}
                        >
                          <p style={{ color: '#fff' }}></p>{' '}
                        </Col>
                      ) : null}
                    </Row>
                  </Col>

                  <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }}>
                    <Row gutter={[24, 24]}>
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        md={{ span: 10, offset: 4 }}
                        sm={{ span: 10, offset: 0 }}
                        xl={{ span: 8, offset: 0 }}
                        className={css.nameSend}
                      >
                        From :
                        {screens.xs === true ? (
                          <p className={css.senderName}>
                            {data.senderName}
                            {/* <p className={css.addressSend}>({data.senderAddress})</p> */}
                          </p>
                        ) : (
                          <>
                            {' '}
                            {data.senderName}
                            {/* <label className={css.addressSend}>({data.senderAddress})</label> */}
                          </>
                        )}
                      </Col>

                      <Col
                        xs={{ span: 12, offset: 0 }}
                        md={{ span: 10, offset: 0 }}
                        sm={{ span: 10, offset: 0 }}
                        xl={{ span: 8, offset: 0 }}
                        className={css.nameRecipient}
                      >
                        To :
                        {screens.xs === true ? (
                          <p className={css.senderName}>
                            {' '}
                            {data.recipientName}
                            {/* <p className={css.addressSend}>({data.recipientAddress})</p> */}
                          </p>
                        ) : (
                          <>
                            {' '}
                            {data.recipientName}
                            {/* <label className={css.addressSend}>({data.recipientAddress})</label> */}
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={{ span: 2 }} md={2} xl={2} sm={2}>
                    <div className={screens.xl === false ? css.boxIcon : css.boxCheckIcon}>
                      <img
                        src={data.IconTrackingCurrentStatus}
                        className={screens.xl === false && screens.xs === true ? css.iconAllMoblie : css.iconAll}
                        alt='Your SVG'
                      />
                    </div>
                  </Col>
                  <Col xs={{ span: 22 }} md={{ span: 22 }} xl={{ span: 21, offset: 0 }} sm={{ span: 22 }}>
                    <Row gutter={screens.xl === false ? [16, 24] : [10, 4]} align='bottom'>
                      <Col
                        xs={{ span: 16, offset: 0 }}
                        md={{ span: 12, offset: 2 }}
                        xl={{ span: 11, offset: 0 }}
                        sm={{ span: 14, offset: 0 }}
                        className={screens.xs === true ? css.trackTextMobile : css.trackText}
                      >
                        {data.orderNo}
                      </Col>
                      <Col
                        xs={{ span: 1, offset: 0 }}
                        md={{ span: 10, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                        sm={{ span: 8, offset: 0 }}
                        className={css.arrowIcon}
                      >
                        {console.log('breakpoint', screens)}

                        <IconButton
                          onClick={() => {
                            setIsItem(!isitem);
                          }}
                        >
                          {isitem === true ? <UpOutlined /> : <DownOutlined />}
                        </IconButton>
                      </Col>
                      <Col
                        xs={{ span: 24, offset: 5 }}
                        md={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        xl={{ span: 12, offset: 0 }}
                        className={css.trackText}
                      >
                        {screens.xl === false ? <p style={{ color: '#fff' }}></p> : null}{' '}
                      </Col>
                    </Row>
                    <Col xs={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
                      <Row gutter={[24, 24]} style={{ paddingLeft: '1%' }}>
                        <Col
                          xs={{ span: 10, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          className={css.nameSend}
                          flex='auto'
                        >
                          From :
                          {screens.xs === true ? (
                            <p>
                              {' '}
                              {data.senderName.substring(0, 13)}
                              <p className={css.addressSend}>({data.senderAddress})</p>
                            </p>
                          ) : (
                            <>
                              {' '}
                              {data.senderName.substring(0, data.senderName.length)}{' '}
                              <label className={css.addressSend}>
                                {/* ({data.senderAddress.slice(str.indexOf('_') + 1);) */}
                                {/* ({data.senderAddress.substring(data.senderAddress.length - 30,data.senderAddress.length)}) */}
                              </label>
                            </>
                          )}
                        </Col>
                        <Col
                          xs={{ span: 10, offset: 4 }}
                          md={{ span: 12, offset: 4 }}
                          sm={{ span: 12, offset: 2 }}
                          xl={{ span: 12, offset: 0 }}
                          className={css.nameRecipient}
                          flex='auto'
                        >
                          To :
                          {screens.xs === true ? (
                            <p>
                              {' '}
                              {data.recipientName}
                              <p className={css.addressSend}>({data.recipientAddress})</p>
                            </p>
                          ) : (
                            <>
                              {' '}
                              {data.recipientName}
                              {/* <label className={css.addressSend}>({data.recipientAddress})</label> */}
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>
              )}

              {/* -------------------------------- */}
              <Row gutter={[24, 24]}>
                <Divider type='horizontal' />
              </Row>

              {isitem === true && (
                <Steps progressDot={customDot} direction='vertical' current={data.currentStatusIndex}>
                  {_.map(data.statusTracking, (item, key) => {
                    return (
                      <Step
                        key={key}
                        title={item.statusName}
                        description={
                          screens.xs === true ? (
                            <div className={css.stepDescription}>
                              {item.statusDescription}
                              <p className={css.stepDateMobile}>{item.timestampTxt}</p>
                            </div>
                          ) : (
                            <Row>
                              <Col span={8}>
                                <div className={css.stepDescription}>{item.statusDescription}</div>
                              </Col>
                              <Col span={8} offset={8}>
                                <div className={css.stepDate}>{item.timestampTxt}</div>
                              </Col>
                            </Row>
                          )
                        }
                      />
                    );
                  })}
                </Steps>
              )}
            </Card>
          </div>
        )
      )}
    </div>
  );
};

TrackingDetail.propTypes = {
  search: PropTypes.any,
};

export default TrackingDetail;
