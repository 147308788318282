import React from 'react';
import { Button as AButton, Grid } from 'antd';
import css from './Iconbutton.module.css';
const { useBreakpoint } = Grid;

const IconButton = (props) => {
  const screens = useBreakpoint();
  return <AButton type='text' className={screens.xl === false ? css.iconButtonMobile : css.iconButton} color='none' {...props} />;
};

export default IconButton;
