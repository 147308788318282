import React, { useState, useLayoutEffect, useEffect } from 'react';
import css from './Tracking.module.css';
import { Layout, Grid } from 'antd';
import TrackingSearch from './trackingsearch/TrackingSearch';
import TrackingDetail from './trackingdetail/TrackingDetail';
import { useDispatch } from 'react-redux';
import { doSearchDataOrderTracking } from '../../saga/TrackingSaga';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryString from "query-string"
import _ from 'lodash';

const { useBreakpoint } = Grid;

const TrackingPage = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);
  const [data, setData] = useState('');
  const screens = useBreakpoint();
  const location = useLocation();
  const params = useParams();
  const { dataQuery } = useQuery('users', location.pathname);
  // const queryParams = new URLSearchParams(window.location.search)
  const queryParams = queryString.parse(window.location.search)
  const searchParams = new URLSearchParams(document.location.search)

  // console.log('WindowLocation',window.location.search);

  useLayoutEffect(() => {
    console.log('dataQuery', dataQuery);
    console.log('pathQuery', queryParams.no);
    console.log('pathparm', searchParams);
    console.log('companyparma', params);
    if (queryParams.no !== undefined) {
      getDataSearch(queryParams.no);
      setSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {

    if (_.get(params,'id')) {
      getDataSearch(_.get(params,'id'));
      setSearch(true);
    }
  }, []);

  //RT230400016
  const getDataSearch = (e) => {
    if (queryParams.no !== undefined) {
      setData(queryParams.no);
    } else {
      setData(e);
    }

    const payload = {
      orderNo: e,
      comCode: params.company,
    };
    console.log('payload search ', payload);
    dispatch(doSearchDataOrderTracking(payload));
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <TrackingSearch setSearch={setSearch} getData={getDataSearch} dataParam={data} />

      <div className={screens.xl === false ? css.containerMobile : css.container}>
        <TrackingDetail search={search} />
      </div>
    </Layout>
  );
};

export default TrackingPage;
