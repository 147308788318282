import React from 'react';
import { Layout, Row, Col, Image,  Grid } from 'antd';
// import { MenuOutlined } from '@ant-design/icons';
import css from './Header.module.css';
import logo from '../../assets/logo/logo_web.svg';

const { useBreakpoint } = Grid;

const HeaderMenu = () => {
  const screens = useBreakpoint();
  // const item = [
  //   { key: '1', label: <div className={css.tracking}>Tracking</div> },
  // ];

  return (
    <Layout className={css.layout}>
      <div className={screens.xl === false ? css.whiteheaderMobile : css.whiteheader}>
        <Row gutter={[0, 12]} >
          <Col xs={3} xl={1}>
            <Image src={logo} />
          </Col>
          <Col xs={{ span: 2, offset: 18 }} xl={{ span: 2, offset: 19 }}>

          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default HeaderMenu;
