import _ from 'lodash';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { loadingStart, loadingStop } from '../redux/slice/ComponentSlice';
import { SEARCH_DATA_ORDER_TRACKING } from '../redux/slice/TrackingSlice';
import Service from '../service/tracking/TrackingService';

// ----------------------------------------------------------

// const doSearchDataListRoundPayment = createAction(actionPrefix.concat('SEARCH_DATA_ORDER_PTRACKING'));

// function* getInitialData(action) {
//   try {
//     yield put({ type: loadingStart })
//     const resp = yield call(Service.initialDataMedicalSSO)
//     yield put({ type: getDataInitialSSO, payload: _.get(resp.data, 'data') })
//   } catch (error) {
//     yield put({ type: loadingStop })
//   }
// }

function* searchDataOrderTracking(action) {
  console.log('searchDataOrderTracking ', action);
  try {
    yield put({ type: loadingStart });
    console.log('searchDataOrderTracking Start ',action.payload);
    const resp = yield call(Service.searchOrderTracking, action.payload);
    console.log('searchDataOrderTracking resp ', resp);
    yield put({ type: SEARCH_DATA_ORDER_TRACKING, payload: _.get(resp, 'data') });
    yield put({ type: loadingStop });
  } catch (error) {
    yield put({ type: loadingStop });
    console.log('error resp');
    // openNotificationWithIcon('error', 'บันทึกไม่สำเร็จ', 'คุณบันทึกไม่สำเร็จ')
  }
}

//-----------------------------------------------------------------------

// function* searchDataListRoundPayment(action) {
//   console.log('searchDataListRoundPayment ', action);
//   try {
//     yield put({ type: loadingStart });
//     const resp = yield call(Service.searchDataListRoundPayment, action.payload);
//     console.log('searchDataListRoundPayment resp ', resp);
//     yield put({ type: SEARCH_DATA_LIST_ROUND_PAYMENTRATE, payload: _.get(resp, 'data') });
//     yield put({ type: loadingStop });
//   } catch (error) {
//     yield put({ type: loadingStop });
//     // openNotificationWithIcon('error', 'บันทึกไม่สำเร็จ', 'คุณบันทึกไม่สำเร็จ')
//   }
// }

const actionPrefix = 'trackingSaga/';

const doSearchDataOrderTracking = createAction(actionPrefix.concat('SEARCH_DATA_ORDER_TRACKING'));

function* watchSaga() {
  yield takeEvery(doSearchDataOrderTracking, searchDataOrderTracking);

  // yield takeEvery(doSearchDataListRoundPayment);
}

export { doSearchDataOrderTracking };

export default watchSaga;
