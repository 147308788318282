import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Space, Grid } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import css from './TrackingSearch.module.css';
import SearchButton from '../../../componets/button/SearchButton';
import PropTypes from 'prop-types';

const { useBreakpoint } = Grid;

const TrackingSearch = ({ setSearch, getData, dataParam }) => {
  const screens = useBreakpoint();
  const [data, setData] = useState('');

  useEffect(() => {
    setData(dataParam);
  }, [dataParam]);

  const handleSearch = () => {
    getData(data);
    setSearch(true);
  };

  const handleChange = (e) => {
    setData(e.target.value);
  };

  return (
    <div className={css.container}>
      <div className={css.inside}>
      </div>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24, offset: 0 }} xl={{ span: 20, offset: 0 }}>
            <label className={screens.xl === false ? css.trackingLabelMobile : css.trackingLabel}>Track and Trace</label>
          </Col>
        </Row>
        <Space> </Space>
        <Row gutter={[12, 20]}>
          <Col xs={{ span: 24, offset: 0 }} xl={{ span: 20, offset: 0 }}>
            <Input
              onChange={handleChange}
              className={screens.xl === false ? css.inputSearchMobile : css.inputSearch}
              placeholder='Enter Tracking No.'
              prefix={<SearchOutlined size='large' style={{ margin: '15px', color: '#bcc5d3' }} />}
              value={data}
            ></Input>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} xl={{ span: 4, offset: 0 }}>
            <SearchButton onClick={() => handleSearch()} size='large'>
              Track
            </SearchButton>
          </Col>
        </Row>
      
    </div>
  );
};

TrackingSearch.propTypes = {
  setSearch: PropTypes.func,
  getData: PropTypes.func,
  dataParam: PropTypes.string,
};

export default TrackingSearch;
