import React from 'react';
import { Layout, Row, Col, Grid } from 'antd';
import css from './FooterPage.module.css';

const { Footer } = Layout;
const { useBreakpoint } = Grid;

const FooterPage = () => {
  const screens = useBreakpoint();
  return (
    <Layout className={css.layout}>
      <Footer className={css.topFooter}>
        <Row>
          <Col span={24} className={css.topFooterText}>
            Customer Service : 02-111-0099
          </Col>
        </Row>
      </Footer>
      <Footer className={screens.xl === false ? css.bottomFooterMobile : css.bottomFooter}>
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 10 }}
            className={css.bottomFooterText}
          >
            Powered by © RAPIDTECH Co., Ltd. (V 1.0.2)
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default FooterPage;
