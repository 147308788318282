import http from '../../util/HttpUtil';

const searchOrderTracking = (payload) => {
  console.log('Call Funtion', payload);
  return http.post(`/order-manager/v5/order/manager/company/${payload.comCode}/web/public/order/tracking`, payload);
};


const TrackingService = {
  searchOrderTracking,
};

export default TrackingService;
