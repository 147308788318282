import React from 'react';
import { Button as SButton, Grid } from 'antd';
import css from './SearchButton.module.css';

const { useBreakpoint } = Grid;

const SearchButton = (props) => {
  const screens = useBreakpoint();
  return (
    <SButton
      className={css.searchButton}
      style={
        screens.xl === false
          ? { height: '40px', width: '100%', borderRadius: '20px', fontSize: '14px' }
          : { height: '56px', width: '100%', borderRadius: '28px', fontSize: '22px' }
      }
      {...props}
    />
  );
};

export default SearchButton;
