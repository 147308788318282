import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataListOrderTracking: {
    data: [],
  },
  getCompany: {
    comCode: '',
  },
};

const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    SEARCH_DATA_ORDER_TRACKING: (state, action) => {
      state.dataListOrderTracking = {
        data: action.payload.data,
      };
    },
    GET_COMPANY: (state, action) => {
      state.dataPaymentRateById = {
        data: action.payload.data || {},
      }
    },
  },
});

const { actions, reducer } = trackingSlice;

export const { SEARCH_DATA_ORDER_TRACKING } = actions;

export default reducer;
