import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  rowPerPage: 10,
  paging: 1,
}

const componentSlice = createSlice({
  name: 'component',
  initialState: () => initialState,
  reducers: {
    loadingStart: (state) => {
      state.isLoading = true
    },
    loadingStop: (state) => {
      state.isLoading = false
    },
    rowPerPageChange: (state, action) => {
      state.rowPerPage = action.payload
    },
    pagingChange: (state, action) => {
      state.paging = action.payload
    },
    resetPageAndSize: (state) => {
      state.paging = 1
      state.rowPerPage = 10
    },
  },
})

const { actions, reducer } = componentSlice
export const { loadingStart, loadingStop, rowPerPageChange, pagingChange, resetPageAndSize } =
  actions
export default reducer
